<template>
  <b-table
    thead-tr-class="border-bottom border-gray text-center"
    tbody-tr-class="border-bottom border-gray custom-row"
    responsive="xl"
    borderless
    :fields="fields"
    :items="data"
  >
    <template #thead-top>
      <b-tr class="text-center header-text">
        <b-th></b-th>
        <b-th></b-th>
        <b-th>{{ $t("page.results.score.globalPerformance.old.title") }}</b-th>
        <b-th>{{ $t("page.results.score.mediaEffectiveness.title") }}</b-th>
        <b-th>{{ $t("page.results.score.attention.title") }}</b-th>
        <b-th>{{ $t("page.results.score.engagement.title") }}</b-th>
        <b-th>{{ $t("page.results.score.digitalPerformance") }}</b-th>
        <b-th colspan="2">{{
          $t("page.results.score.mediaOptimization.title")
        }}</b-th>
      </b-tr>
    </template>

    <template #head()="data">
      <div class="text-small">{{ data.label.toUpperCase() }}</div>
    </template>

    <template #cell(src)="data">
      <video width="100px" :src="`${data.value}#t=1.5`" />
    </template>

    <template #cell(globalPerformance)="data">
      <div
        :style="{ color: getColor(data.value) }"
        class="text-center mt-3 emotional-response"
      >
        {{ data.value }}
      </div>
    </template>

    <template #cell(gender)="data">
      <div class="text-center font-weight-bold mt-3">
        {{ data.value.value }}%
      </div>
      <div
        style="font-size: 0.6rem; "
        :style="{ color: getGenderColor(data.value.label) }"
        class="text-center font-weight-bold text-uppercase"
      >
        {{ $t(data.value.label.toLowerCase()) }}
      </div>
    </template>

    <template #cell(age)="data">
      <div class="text-center font-weight-bold mt-3">
        {{ data.value.value }}%
      </div>
      <div
        style="font-size: 0.6rem;"
        :style="{ color: getAgeColor(data.value.label) }"
        class="text-center font-weight-bold"
      >
        {{ data.value.label.toUpperCase() }}
      </div>
    </template>

    <template #cell()="data">
      <div
        class="text-center font-weight-bold mt-3"
        v-if="data.field.key !== 'name'"
      >
        {{ data.value }}%
      </div>

      <div
        style="font-size: 0.9rem; width: auto"
        class="font-weight-light mt-3"
        v-else
      >
        {{ data.value }}
      </div>
    </template>

    <template #cell(actions)="data">
      <b-icon-arrow-right-circle-fill
        @click="onClickArrow(data)"
        class="ml-3 clickable mt-3"
        scale="1.4"
      ></b-icon-arrow-right-circle-fill>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [
        {
          key: "src",
          label: ""
        },
        {
          key: "name",
          label: ""
        },
        {
          key: "globalPerformance",
          label: ""
        },
        {
          key: "mediaEffectiveness",
          label: "Eco Score"
        },
        {
          key: "attention",
          label: ""
        },
        {
          key: "engagement",
          label: ""
        },
        {
          key: "digitalPerformance",
          label: "Predictive Click"
        },
        {
          key: "gender",
          label: "Gender"
        },
        {
          key: "age",
          label: "Age"
        },
        {
          key: "actions",
          label: ""
        }
      ]
    };
  },

  methods: {
    onClickArrow(data) {
      this.$emit("clickedDetails", data.index);
    },

    getGenderColor(gender) {
      const t = gender.toLowerCase();
      if (t === "female") {
        return "red";
      } else {
        return "rgba(61, 174, 255)";
      }
    },

    getAgeColor(age) {
      const t = age.toLowerCase();

      if (t === "under 34") {
        return "var(--green)";
      } else {
        return "rgba(255, 124, 38)";
      }
    },

    getColor(value) {
      if (value < 4) {
        return "red";
      } else if (value < 7) {
        return "var(--orange)";
      } else {
        return "var(--green)";
      }
    }
  }
};
</script>

<style>
.header-text > th {
  font-weight: 300;
  font-size: 0.9rem;
  color: var(--red);
}
.custom-row {
  line-height: 100% !important;
}

.custom-row > td:first-child {
  padding-left: 0;
}

.custom-row > td:last-child {
  padding-right: 0 !important;
}
.custom-row > td {
  padding-top: 28px;
  padding-bottom: 28px;
}
</style>

<style scoped>
.clickable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.emotional-response {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.text-small {
  font-weight: 700;
  font-size: 0.7em;
}
</style>
