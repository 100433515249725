<template>
  <div class="table-container">
    <div class="fixed-column">
      <div class="column first">
        <div class="video-cell"></div>
        <div class="cell cell-label">{{ $t("page.results.globalIndex") }}</div>

        <div class="cell cell-label">{{ $t("page.results.valence") }}</div>

        <div class="cell cell-label">{{ $t("page.results.attention") }}</div>

        <div class="cell cell-label">
          {{ $t("page.results.attentionSpan") }}
        </div>

        <div class="cell cell-label">{{ $t("page.results.engagement") }}</div>

        <!--
        <div class="cell cell-label">
          {{ $t("page.results.contentEffectiveness") }}
        </div>
         -->

        <div class="cell cell-label">
          {{ $t("page.results.digitalPerformance") }}
        </div>

        <div class="cell cell-label">
          {{ $t("page.results.mediaOptimization") }}

          <div class="cell-optimization ml-5 text-gray-dark">
            {{ $t("gender") }}
          </div>

          <div class="cell-optimization ml-5 text-gray-dark">
            {{ $t("age") }}
          </div>
        </div>

        <div class="cell cell-label"></div>
      </div>
    </div>
    <div
      class="scrollable-container"
      :class="{ 'shadow-custom': enableShadow }"
      @scroll="onScroll($event)"
    >
      <div ref="columns" class="column" :key="i" v-for="(item, i) of data">
        <div class="video-cell">
          <video width="100%" style="max-height: 155px;" :src="item.src"></video>
          <div class="mt-2">{{ item.name }}</div>
        </div>
        <div class="cell globalPerformance">{{ item.globalPerformance }}%</div>
        <div
          class="cell valence text-uppercase"
          :style="{ color: getValenceColor(item.valence) }"
        >
          {{ getValenceText(item.valence) }}
        </div>
        <div class="cell attention">{{ item.attention }}%</div>
        <div class="cell attentionSpan">{{ item.attentionSpan }} sec.</div>
        <div class="cell engagement">{{ item.engagement }}%</div>
        <!-- <div class="cell mediaEffectiveness">
          {{ item.mediaEffectiveness }}%
        </div> -->
        <div class="cell digitalPerformance">
          {{ item.digitalPerformance }}%
        </div>
        <div class="cell ">
          <br />
          <div class="cell-optimization">
            {{ $t(item.gender.label.toLowerCase()) }}
          </div>
          <div class="cell-optimization">{{ item.age.label }}</div>
        </div>

        <div class="cell">
          <b-icon-arrow-right-circle-fill
            @click="onClickArrow(i)"
            class="mt-5 clickable text-primary"
            scale="1.5"
          ></b-icon-arrow-right-circle-fill>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      initialPosition: null,
      enableShadow: false
    };
  },

  mounted() {
    this.initialPosition = this.$refs.columns[0].getBoundingClientRect().left;
  },

  computed: {
    mockData() {
      return new Array(20).fill(this.data[0]);
    }
  },

  methods: {
    onScroll(event) {
      const positionAfterScroll = this.$refs.columns[0].getBoundingClientRect()
        .left;
      if (positionAfterScroll < this.initialPosition) {
        this.enableShadow = true;
      } else {
        this.enableShadow = false;
      }

      console.log(this.enableShadow);
    },

    onClickArrow(index) {
      this.$emit("clickedDetails", index);
    },

    getGenderColor(gender) {
      const t = gender.toLowerCase();
      if (t === "female") {
        return "red";
      } else {
        return "rgba(61, 174, 255)";
      }
    },

    getAgeColor(age) {
      const t = age.toLowerCase();

      if (t === "under 34") {
        return "var(--green)";
      } else {
        return "rgba(255, 124, 38)";
      }
    },

    getValenceText(value) {
      if (value < 0) {
        return this.$t("negative");
      } else if (value > 0) {
        return this.$t("positive");
      }
      return value;
    },

    getValenceColor(value) {
      if (value === "negative" || parseFloat(value) < 0) {
        return "red";
      } else if (value > 0) {
        return "green";
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  margin: 2rem;
  margin-top: 5rem;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fixed-column {
  width: 10%;
  text-overflow: ellipsis;
  min-width: 230px;
  height: 100%;
}

.scrollable-container {
  width: 90%;
  min-width: 150px;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  transition: all 200ms ease;
}

.shadow-custom {
  box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.171) inset;
}

.column {
  text-align: center;
  min-width: 230px;
  width: 230px;
  /* padding: 0 2rem; */
}
/*
.column:not(.first):hover .cell,
.column:not(.first):hover .video-cell {
  background: var(--gray-light);
  transition: all 50ms ease-in-out;
} */

.column.first {
  text-align: start;
}

.column.first .cell {
  color: var(--red);
}

.cell {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  min-height: 65px;
  line-height: 65px;
  color: var(--gray-dark);
}

.cell-label {
  font-weight: 300;
}

.cell .cell-optimization {
  height: 30px;
  line-height: 30px;
}

.cell .cell-optimization:last-child {
  margin-bottom: 1rem;
}

.cell .cell-small {
  margin-left: 2rem;
  color: var(--gray-dark);
  min-height: 0;
}

.fixed-column .cell:after {
  content: "";
  width: calc(100vw - 200px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-bottom: 1px solid var(--gray-light);
}

.cell.globalPerformance {
  font-size: 1.8rem;
  font-weight: 700;
}

.cell.valence {
  text-transform: uppercase;
  font-weight: 700;
}

.video-cell {
  height: 200px;
  padding: 0 2rem;
}

/* .clickable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.emotional-response {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.text-small {
  font-weight: 700;
  font-size: 0.7em;
} */
</style>
